@import '../../colors.scss';

.link-create-component {
    height: 100%;
    padding-top: 40px;
    color: $black;
    overflow-y: auto;

    .form-container {
        text-align: center;
        max-width: 600px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        margin-bottom: 150px;
    }

    .form-title {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .form-item {
        margin-bottom: 30px;
    }

    .text-field {
        width: 100%;
    }

    .form-sub-header {
        font-size: 24px;
        font-weight: 700;
        margin: 50px 0 20px 0;
    }

    .ways-to-share {
        display: flex;
        gap: 30px;

        .domain-container {
            width: 30%;
        }
    
        .custom-slug {
            width: 70%;
        }
    }

    .divider {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 24px;
        padding-bottom: 6px;
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 20px;
    }

    a {
        color: $blue2;
        text-decoration: none;
    }

    .remaining-actions {
        font-size: 16px;
        margin-top: 8px;
        color: #555;

        .remaining {
            font-weight: 700;
            color: #333;
        }
    }

    .no-remaining-container {
        background: $blue4;
        padding: 40px 20px;
        border-radius: 4px;
        margin-bottom: 40px;
        font-weight: 600;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid $primary;
        color: $primary;

        .no-remaining-header {
            font-size: 22px;
            margin: auto;
        }
    }

    .no-custom-remaining {
        margin-top: 10px;

        .no-custom-header {
            font-weight: 600;
        }
    }
}

@media only screen and (max-width: 600px) {
    .link-create-component {
        .ways-to-share {
            gap: 40px;
            flex-direction: column;

            .domain-container, .custom-slug {
                width: 100%;
            }
        }

        .divider {
            display: none;
        }

        .btn-container {
            margin-top: 40px;
        }

        .no-remaining-container {
            padding: 40px 10px;
        }
    }
}