@import '../../colors.scss';

.custom-success-toast {
    background-color: $green2;
    color: $white;
}

.custom-info-toast {
    background-color: $blue3;
    color: $white;
}

.custom-warning-toast {
    background-color: $orange2;
    color: $white;
}

.custom-error-toast {
    background-color: $red2;
    color: $white;
}