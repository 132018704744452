@import '../../colors.scss';

.signup-billing-container {
    // .main-content {}

    .back-icon {
        font-size: 14px;
    }

    .form-header {
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
    }

    .plan-header {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 500;
        color: rgb(51, 51, 51);
        text-align: center;
    }

    .back-btn-container {
        text-align: left;
        .back-btn {
            cursor: pointer;
            font-size: 15px;
        }
    }

    .plans-container {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-bottom: 30px;

        .plan {
            width: 100px;
            padding: 10px;
            border: 1px solid $grey2;
            cursor: pointer;
            border-radius: 4px;
        }

        .selected-plan {
            border: 2px solid $blue2;
        }

        .plan-name {
            font-weight: 500;
        }
    }

    .payment-btn {
        margin-top: 30px;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    .signup-billing-container {
        .text-field, .form-control {
            width: 100%;
        }

        .back-btn-container {
            margin-bottom: 20px;
        }
    }
}