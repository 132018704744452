@import '../../../colors.scss';

.pie-chart-container {
    height: 400px;

    .no-data {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: darkgrey;
        margin-top: -40px;
    }

    .loader {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $secondary;
        margin-top: -40px;
    }

    .icon {
        font-size: 42px;
    }
}

@media only screen and (max-width: 600px) {
    .pie-chart-container {
        height: 430px;
    }
}