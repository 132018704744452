@import '../../colors.scss';

.plans-component {
    height: 100%;
    padding-top: 100px;
    background: $grey;
    overflow-y: auto;

    .error {
        text-align: center;
        // color: $red;
        max-width: 800px;
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin: auto;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .error-header {
            font-size: 24px;
        }
    }

    .plans {
        border-radius: 8px;
        display: flex;
        max-width: 1200px;
        justify-content: center;
        margin: auto;
        flex-wrap: wrap;
        gap: 80px;
        margin-bottom: 150px;
    }

    .plan {
        text-align: center;
        // margin: auto;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background: $white;
        padding-bottom: 40px;
        width: 300px;
        border: 2px solid $white;

        .name {
            color: $black;
            padding: 20px 40px;
            border-radius: 5px 5px 0 0;
            border-bottom: 1px solid $grey2;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 18px;
        }

        .selected-name {
            color: $white;
            background: $primary;
            border: none;
        }

        .price {
            color: $black;
            padding: 0 40px;
            margin-top: 40px;
            font-size: 32px;

            .price-1 {
                font-weight: 900;
            }
            .price-2 {
                font-size: 20px;
                font-weight: 300;
            }
        }

        .details {
            margin: 20px 0 40px 0;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .detail-highlight {
                font-weight: 600;
            }
        }

        .btn-container {
            margin-bottom: 40px;
            .selected-btn {
                background: $secondary;
            }
        }

        .description {
            color: $black;
            padding: 0 40px;
            margin-bottom: 40px;
        }
    }

    .selected-plan {
        border: 2px solid $primary;
        border-radius: 8px;
    }

    .plans-loader {
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .content {
            margin-top: -400px;
            padding: 0 10px;
        }

        .icon {
            font-size: 42px;
            color: $secondary;
        }
    }
}

@media only screen and (max-width: 900px) {
    .plans-component {
        padding-top: 40px;

        .plans {
            gap: 40px;
        }
    }
}