@import '../../colors.scss';

.checkout-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    text-align: center;

    .modal-content {
        max-width: 450px;
        width: 100%;
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .loading-message {
        display: flex;
        gap: 10px;
        margin: 20px 0;
        font-size: 24px;
        text-align: center;
        justify-content: center;

        .icon {
            color: $blue3;
        }
    }

    .animate {
        animation: shake 1s ease-in-out infinite;
        /* Define the animation */
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }

        25% {
            transform: translateX(-1px);
        }

        50% {
            transform: translateX(1px);
        }

        75% {
            transform: translateX(-1px);
        }

        100% {
            transform: translateX(0);
        }
    }
}