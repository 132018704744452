@import '../../colors.scss';

.verify-component {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content {
        margin-top: -100px;
        padding: 0 10px;

        .header {
            margin: 0;
        }

        .details {
            margin: 8px 0;
        }
    }

    .icon {
        margin-right: 8px;
    }

    .verified-logo {
        color: $green;
    }

    .btn-container {
        margin-top: 20px;
    }

    .spacer {
        margin-top: 10px;
        height: 60px;
    }
}