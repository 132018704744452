.notfound-component {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content {
        margin-top: -100px;
        .header {
            margin: 0;
        }
        .details {
            margin: 0;
        }
    }

    .btn {
        margin-top: 20px;
    }
}