@import '../colors.scss';

.route-loader {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content {
        margin-top: -100px;
        padding: 0 10px;
    }

    .icon {
        font-size: 42px;
        color: $secondary;
    }
}