@import '../../colors.scss';

.header-component {
    position: sticky;
    top: 0;
    background: $white;
    padding: 1rem 0;
    border-bottom: 1px solid $grey2;
    z-index: 100;

    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .nav-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        width: 100%;
    }

    .nav-item {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
    }

    .nav-item:first-child {
        flex-grow: 1;
    }

    .nav-item:last-child {
        margin-right: 0;
    }

    .nav-item a {
        text-decoration: none;
        color: $primary;

        &:hover {
            color: $blue2;
        }
    }

    .nav-dekstop {
        display: flex;
    }

    .nav-mobile {
        display: none;
    }

    .main-link {
        font-weight: 900;
        font-size: 24px;
    }

    .btn {
        background: $secondary;
    }

    .btn:hover {
        background: $orange2;
    }

    .upgrade-btn {
        background: $blue3;
    }

    .upgrade-btn:hover {
        background: $blue2;
    }

    .dropdown-container {
        text-align: right;
    }

    .dropdown {
        position: relative;
        display: inline-block;

        .dropdown-toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 100%;
        }

        .dropdown-toggle:hover {
            background-color: #f5f5f5;
        }

        .user-initial-circle {
            width: 34px;
            height: 34px;
            background-color: $primary;
            border-radius: 50%;
            color: $white;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }

        .user-name {
            font-size: 16px;
            text-align: left;
            padding: 0 2px;
        }

        .dropdown-menu {
            position: absolute;
            top: 100%;
            right: 0;
            background-color: $white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 10px;
            border-radius: 4px;
            z-index: 1;
            border: 1px solid $grey2;
            margin-top: 10px;
            width: 100%;
            min-width: 250px;

            .user-detail {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $grey2;

                .user-initial-circle {
                    margin-top: -8px;
                }

                .user-name {
                    margin-top: 10px;
                    font-size: 18px;
                    padding: 0;
                }

                .email {
                    margin: 2px 0 20px 0;
                    font-size: 13px;
                }
            }

            .body-link {
                padding: 4px 0;
                font-size: 16px;
                margin-bottom: 2px;
            }

            .body-link:hover {
                background-color: #f5f5f5;
            }

            .upgrade-link {
                margin-top: 10px;

                a {
                    color: $blue3;
                    font-weight: 500;
                }
            }

            .signout-block {
                border-top: 1px solid $grey2;
                padding-top: 10px;
                font-size: 16px;
            }
        }

        .dropdown-menu a {
            display: block;
            padding: 8px;
            color: $black;
            text-decoration: none;
            text-align: left;
        }

        // .dropdown-menu a:hover {
        //     background-color: #f5f5f5;
        // }
    }

    .signout-btn {
        width: 100%;
        justify-content: left;
        color: $black;
        font-weight: 400;
        font-size: 16px;
    }

    .signout-btn:hover {
        background-color: #f5f5f5;
    }

    .link-icon {
        margin-left: 8px;
        font-size: 12px;
    }

    .toggle-icon {
        font-size: 22px;
        cursor: pointer;
    }

    .mobile-navigation {
        width: 100%;
        color: $black;
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: $white;
        display: none;

        .mobile-nav-content {
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .mobile-header {
            // border: 1px solid white;
            display: flex;
            // margin: 0;
            // padding: 0;
            // border: 1px solid;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .close-icon {
            text-align: end;
            // border: 1px solid;
            // margin: 20px;
            margin-top: 0px;

            .toggle-icon-close {
                font-size: 28px;
            }
        }

        .fake-btn {
            // border: 1px solid;
            text-align: center;
            border-radius: 4px;
            display: flex;
            font-weight: 500;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .link {
            flex-grow: 1;
            // border: 1px solid;
        }

        .sign-up {
            background: $secondary;
            padding: 3px;

            .link {
                color: white !important;
            }
        }

        .sign-in {
            border: 1px solid $primary;

            .link {
                color: $primary !important;
            }
        }

        .divider {
            border-top: 1px solid $grey2;
            margin: 20px 0;
        }
    }

    .upgrade-item {
        margin-right: 20px;
    }

    .mobile-navigation.expanded {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .header-component {
        .nav-dekstop {
            display: none;
        }

        .nav-mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .mobile-navigation.expanded {
            display: block;
        }

        .upgrade-item {
            display: none;
        }
    }
}