@import '../../colors.scss';

.signin-component {
    height: 100%;
    padding-top: 40px;
    background: $grey;
    overflow-y: auto;

    .form-container {
        text-align: center;
        max-width: 600px;
        margin: auto;
        padding: 50px 60px 80px 60px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background: $white;
    }

    .form-title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .form-sub-title {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .form-item {
        max-width: 400px;
        margin: 0 auto 30px auto;

        .text-field {
            width: 100%;
        }
    }

    .btn-container {
        margin-bottom: 10px;

        .btn {
            font-size: 17px;
            padding: 6px 30px;
            font-weight: 400;
        }
    }

    .error-container {
        margin-bottom: 20px;
        color: $red;
    }
}

@media only screen and (max-width: 600px) {
    .signin-component {
        background: $white;

        .form-container {
            padding: 0px 20px;
            border: none;
            margin: 0 10px;
            box-shadow: none
        }

        .text-field {
            width: 100%;
        }
    }
}