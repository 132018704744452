@import '../../../colors.scss';

.bar-chart-container {
    height: 400px;

    .custom-tooltip {
        background: $white;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid $grey2;

        .title {
            font-weight: 700;
        }

        .dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #6699CC;
            display: inline-block;
            margin-bottom: 1px;
            margin-right: 4px;
        }
    }

    .no-data {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: darkgrey;
        margin-top: -40px;
    }

    .loader {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $secondary;
        margin-top: -40px;
    }

    .icon {
        font-size: 42px;
    }
}

@media only screen and (max-width: 600px) {
    .bar-chart-container {
        height: 430px;
    }
}