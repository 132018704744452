@import '../../colors.scss';

.link-card-container {
    color: $black;

    .link-card {
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        gap: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    a {
        color: $black;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .header-container {
        display: flex;
    }

    .link-title {
        font-size: 20px;
        font-weight: 600;
    }

    .link-controls-desktop, .link-controls-mobile {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        gap: 10px;
    }

    .link-controls-mobile {
        border-top: 1px solid $grey2;
        padding-top: 10px;
        display: none;
    }

    .slug-link {
        color: $blue2;
    }

    .card-footer {
        display: flex;
        font-size: 14px;
        margin-top: 20px;
    }

    .footer-item {
        display: flex;
        gap: 6px;
    }
}

@media only screen and (max-width: 900px) {
    .link-card-container {
        .link-controls-desktop {
            display: none;
        }

        .link-controls-mobile {
            display: flex;
        }
    }
}