@import '../../colors.scss';

.link-details-container {
    height: 100%;
    padding-top: 40px;
    color: $black;
    background: $grey;
    overflow-y: auto;

    .main-content {
        text-align: center;
        max-width: 1200px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        margin-bottom: 150px;
        // this is for the analytics page nested inside of details main content
        .main-content {
            padding: 0;
        }
    }

    a {
        color: $black;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .link-container {
        margin-bottom: 40px;
    }

    .link-icon {
        font-size: 13px;
        margin-right: 6px;
    }

    .card {
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        gap: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .loading-container {
        min-height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .loader {
            height: 100%;
            text-align: center;
            color: $secondary;
        }

        .icon {
            font-size: 42px;
        }
    }

    .bad-link {
        min-height: 196px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .bad-link-icon {
        font-size: 30px;
        color: $blue2;
    }

    .analytics-header {
        text-align: center;
    }

    .detail-analytics-container {
        margin-top: 6px;
    }
}