@import '../../colors.scss';

.main-component {
    height: 100%;
    padding-top: 60px;
    overflow-y: auto;
    color: $black;

    .main-content {
        text-align: left;
        margin: auto;
        margin-bottom: 150px;
    }

    .header-block {
        padding: 40px 10px 100px 10px;
        display: flex;
        justify-content: space-around;
        max-width: 1200px;
        margin: auto;
        gap: 40px;

        .head-text-container {
            max-width: 500px;
        }

        .header {
            font-size: 60px;
            margin-bottom: 10px;

            span {
                color: $secondary;
            }
        }

        .description {
            font-size: 20px;
            line-height: 1.3;
        }

        .img-container {
            max-width: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .lemur-img {
                width: 100%;
            }
        }

        .btn-container {
            margin: 20px 0;
            .btn {
                font-size: 18px;
                padding: 10px 30px;
                font-weight: 400;
            }
        }
    }

    .about-block {
        padding: 60px 10px;
        background: $grey;
        font-size: 20px;
        line-height: 1.3;

        .about-content {
            padding: 100px 0;
            display: flex;
            justify-content: space-around;
            max-width: 1200px;
            margin: auto;
            gap: 40px;

            .about-description {
                max-width: 500px;
            }

            .about-description-block {
                margin-bottom: 20px;
                font-size: 30px;
                color: $blue2;
            }

            .about-header {
                font-size: 38px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .analytics-block {
        padding: 60px 10px;
        background: $white;
        font-size: 20px;
        line-height: 1.3;
        text-align: center;

        .analytics-content {
            padding: 40px 0;
            max-width: 1200px;
            margin: auto;

            .analytics-header {
                font-size: 38px;
                text-align: center;
            }

            .img-container {
                border-radius: 6px;
                .analytics-img {
                    width: 100%;
                    border-radius: 6px;
                }
            }
        }
    }

    .example-block {
        padding: 20px 10px 100px 10px;
        max-width: 800px;
        margin: auto;
        text-align: center;

        .example-header {
            font-size: 20px;
            color: $secondary;
        }

        .example-sub-header {
            font-size: 30px;
            font-weight: 600;
            color: $primary;
        }

        .form-header {
            text-align: left;
            font-weight: 600;
            font-size: 22px;
            margin: 60px 0 24px 0;
        }

        .form-item {
            margin-bottom: 30px;
            .text-field {
                width: 100%;
            }
        }

        .last-item {
            display: flex;
            gap: 20px;
        }

        .divider {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            font-size: 24px;
            padding-bottom: 6px;
        }

        .domain, .back-half {
            width: 100%;
            .text-field {
                width: 100%;
            }
        }

        .domain {
            width: 40%;
        }

        .back-half {
            flex-grow: 1;
        }

        .form-footer {
            margin: 30px 0;
            text-align: left;
            background: $blue4;
            color: $blue2;
            padding: 20px 10px;
            border-radius: 6px;
            font-size: 18px;

            .footer-icon {
                margin: 0px 4px;
            }
        }

        .btn-container {
            text-align: left;
            .btn {
                font-size: 17px;
                padding: 10px 20px;
                font-weight: 400;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .main-component {
        .header-block {
            flex-direction: column-reverse;
            padding: 10px;
            gap: 20px;

            .img-container {
                margin: auto;
            }

            .header {
                text-align: center;
            }

            .btn-container {
                text-align: center;
                margin: 40px 0;
            }
        }

        .about-block {
            padding: 10px;

            .about-content {
                padding-top: 60px;
                flex-direction: column-reverse;

                .about-description-block {
                    font-size: 24px;
                }
            }
        }

        .analytics-block {
            padding: 40px 10px;

            .analytics-description {
                margin: 10px 0;
            }
        }

        .example-block {
            padding-top: 40px;

            .example-sub-header {
                font-size: 26px;
                margin-top: 10px;
            }

            .btn-container {
                text-align: center;
            }

            .form-footer {
                font-size: 16px;
            }

            .last-item {
                flex-direction: column;
                gap: 30px;
                .divider {
                    display: none;
                }
                .domain, .back-half {
                    width: 100%;
                }
            }
        }
    }
}