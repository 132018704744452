.date-range-container {
    display: flex;

    .separator {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        font-size: 48px;
        font-weight: 100;
        padding: 0 20px;
    }

    .text-field {
        flex-grow: 1;
    }

    input {
        text-align: left !important;
    }
}