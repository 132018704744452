@import '../../../colors.scss';

.filter-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;

    .modal-content {
        max-width: 450px;
        width: 100%;
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .modal-header-container {
        display: flex;
        font-size: 26px;
        margin-bottom: 20px;

        .modal-header {
            flex-grow: 1;
        }

        .modal-close-btn {
            color: grey;
            cursor: pointer;
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .btn-container {
        margin-top: 50px;
        display: flex;
        gap: 14px;
    }

    .reset-btn {
        flex-grow: 1;
    }

    .date-range-container{ 
        width: 100%;
    }

    .date-created-label {
        margin-bottom: -12px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
    }

    .reset-icon {
        margin-right: 6px;
    }
}

@media only screen and (max-width: 900px) {
    .filter-modal {
        .modal-content {
            margin: 10px;
            padding: 30px 20px;
        }
    }
}