@import '../../../colors.scss';

.user-edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;

    .modal-content {
        max-height: 70%;
        max-width: 600px;
        width: 100%;
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
    }

    .modal-header-container {
        display: flex;
        font-size: 26px;

        .modal-header {
            flex-grow: 1;
        }

        .modal-close-btn {
            color: grey;
            cursor: pointer;
        }
    }

    .form-container {
        margin-top: 30px;

        .form-item {
            margin-bottom: 20px;
        }

        .text-field {
            width: 100%;
        }

        .flex {
            display: flex;
            gap: 20px;
        }

        .half {
            width: 50%;
        }

        .btn-container {
            margin-top: 40px;
            display: flex;
            gap: 14px;

            .delete-btn {
                flex: 1;
            }
        }
    }

    .delete-text {
        margin-top: 30px;
        font-size: 18px;
    }

    .btn-container-delete {
        margin-top: 40px;
        display: flex;
        gap: 14px;
        justify-content: flex-end;
    }
}

@media only screen and (max-width: 900px) {
    .user-edit-modal {
        .modal-content {
            margin: 10px;
            padding: 30px 20px;
        }
    }
}