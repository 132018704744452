@import '../../colors.scss';

.signup-component {
    height: 100%;
    padding-top: 40px;
    background: $grey;
    overflow-y: auto;

    .form-container {
        text-align: center;
        max-width: 600px;
        margin: auto;
        padding: 40px 60px 80px 60px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background: $white;
        margin-bottom: 250px;
    }

    .registered {
        padding-top: 70px;
        font-size: 18px;

        .success {
            font-size: 24px;
            color: $green;
            margin-bottom: 4px;

            .icon {
                font-size: 22px;
            }
        }
    }

    .form-title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .form-sub-title {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .captcha-container {
        display: inline-block;
    }

    .form-item {
        max-width: 500px;
        margin: 0 auto 30px auto;

        .text-field {
            width: 100%;
        }
    }

    .btn-container {
        margin-bottom: 20px;
        .btn {
            font-size: 17px;
            padding: 8px 34px;
            font-weight: 400;
        }
    }

    .disclaimer {
        width: 350px;
        font-size: 14px;
        margin: auto;
        color: darkgray;
        text-align: left;
    }

    .link {
        color: darkgray;
    }

    .steps-container {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-bottom: 20px;
        font-size: 14px;

        div {
            padding: 6px;
            border-radius: 6px;
            border: 2px solid $white;
        }
    }

    .active-step {
        border: 2px solid $blue2 !important;
        font-weight: 500;
        border-radius: 6px;
    }

    .captcha-error {
        border: 2px solid red;
        padding: 2px 1px 0px 2px;
        border-radius: 4px;
    }

    .billing-container {
        max-width: 600px;
        margin: auto;
        padding: 40px 60px 80px 60px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background: white;
        border-radius: 8px;
        margin-bottom: 200px;
    }
}

@media only screen and (max-width: 600px) {
    .signup-component {
        padding-top: 30px;

        .billing-container {
            padding: 20px;
            box-shadow: none;
        }
    }
}