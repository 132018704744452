$primary: #002948;
$blue2: #004e75;
$blue3: #6699CC;
$blue4: #EBF5FF;
$blue5: #004080; // color of primary in theme for btns
$secondary: #ff8c00;
$grey: #f5f6f7;
$grey2: #c4c4c5;
$green: #006400;
$green2: #2E8B57;
$black: #333;
$white: #fff;
$orange2: #ff7f00;
$orange3: #FF6347;
$red: #ff0000;
$red2: #FF6666;
$yellow: #FFE135;
$yellow2: #FFB347;
$purple: #BA55D3;