@import '../../colors.scss';

.manage-plan-container {
    height: 100%;
    padding-top: 40px;
    color: $black;
    background: $grey;
    overflow-y: auto;

    .main-content {
        text-align: center;
        max-width: 1200px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 150px;
    }

    .support-header {
        font-size: 32px;
        font-weight: 700;
    }

    .card {
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .support-message {
        margin-top: 40px;
        max-width: 600px;
    }

    .support-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .support-item-header {
        font-size: 20px;
        font-weight: 500;
    }

    .plan-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .plan-header {
        font-size: 26px;
    }

    .bold {
        font-weight: 500;
    }

    .plan-sub-header {
        font-size: 18px;
    }
}

@media only screen and (max-width: 900px) {
    .manage-plan-container {
        padding-top: 20px;

        .card {
            padding: 30px 10px;
        }
    }
}