@import '../../colors.scss';

.upgrade-container {
    height: 100%;
    padding-top: 40px;
    color: $black;
    background: $white;
    overflow-y: auto;

    .main-content {
        text-align: center;
        max-width: 1200px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 150px;
    }

    .header-container {
        text-align: center;
        max-width: 800px;
        margin: auto;
    }

    .upgrade-header {
        font-size: 38px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .upgrade-sub-header {
        font-size: 20px
    }

    .card {
        border-radius: 8px;
        // background: $white;
        // border: 1px solid $white;
        padding: 30px 40px;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .plans {
        border-radius: 8px;
        display: flex;
        max-width: 1200px;
        justify-content: center;
        margin: auto;
        flex-wrap: wrap;
        gap: 80px;
    }

    .plan {
        text-align: center;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background: $white;
        padding-bottom: 40px;
        width: 300px;
        border: 1px solid $grey2;

        .name {
            color: $black;
            padding: 20px 40px;
            border-radius: 5px 5px 0 0;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 22px;
            margin-top: 10px;
        }

        .price {
            color: $blue5;
            padding: 0 40px;
            font-size: 40px;

            .price-1 {
                font-weight: 700;
            }
            .price-2 {
                font-size: 20px;
                font-weight: 300;
            }
        }

        .details {
            margin: 20px 0 40px 0;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .detail-highlight {
                font-weight: 600;
            }
        }

        .btn-container {
            margin-bottom: 40px;
        }

        .description {
            color: $black;
            padding: 0 40px;
            margin-bottom: 40px;
        }
    }

    .plans-loader {
        min-height: 400px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $secondary;

        .icon {
            font-size: 42px;
        }
    }

    .billing-container {
        max-width: 600px;
        margin: auto;
    }

    .success-container {
        text-align: center;
        padding: 100px 20px;
        // background: $blue4;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        font-size: 18px;
        border: 1px solid $grey2;

        .success {
            font-size: 28px;
            margin-bottom: 4px;

            .icon {
                font-size: 22px;
                color: $green;
            }
        }

        a {
            text-decoration: none;
            color: $black;
            font-weight: 500;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

@media only screen and (max-width: 900px) {
    .upgrade-container {
        padding-top: 20px;

        .card {
            padding: 30px 0px;
        }

        .success-container {
            padding: 80px 6px;
        }
    }
}