@import '../../colors.scss';

.payment-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .card {
        border: 1px solid #ced4da;
        padding: 12px;
        border-radius: 4px;
    }

    .card:hover {
        border: 1px solid $black;
    }

    .number-container {
        position: relative;
    }

    .brand-logo {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
    }

    .card-label {
        text-align: left;
        margin-bottom: -12px;
        font-size: 16px;
        font-weight: 500;
        color: rgb(51, 51, 51);
    }

    .billing-details {
        // border: 1px solid;
        margin-top: 20px;

        .billed-now {
            font-weight: 500;
            font-size: 20px;
        }
    
        .billed-explain {
            font-size: 16px;
            opacity: .7;
        }
    }

    .btn-container {
        margin-bottom: 20px;
        .btn {
            font-size: 17px;
            padding: 8px 34px;
            font-weight: 400;
        }
    }

    .flex {
        display: flex;
        gap: 20px;

        .card-label {
            margin-bottom: 8px;
        }

        .half {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .signup-component {
        background: $white;

        .form-container {
            padding: 0px 20px;
            border: none;
            box-shadow: none;
        }

        .text-field, .form-control {
            width: 100%;
        }
    }
}