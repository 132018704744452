@import '../../colors.scss';

.link-table-component {
    height: 87%;

    .filters-container {
        display: flex;
        padding: 20px;
        gap: 30px;
        background: $white;
        border-radius: 6px 6px 0 0;

        .filter-item {
            width: 100%;
            max-width: 300px;

            .text-field {
                width: 100%;
            }
        }

        .date-range-container{ 
            width: 100%;
        }

        .filter-btn-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 2px;
        }

        .filter-btn {
            border: 1px solid $grey2;
        }

        .btn-label {
            margin-left: 6px;
        }
    }

    .links-table {
        height: 100%;
        border-top: 1px solid $grey2; // material's color
        overflow: auto;
        position: relative;
        scrollbar-width: none; // Hide scrollbar for Firefox
        -ms-overflow-style: none; // Hide scrollbar for IE and Edge
        /* Hide scrollbar for Chrome, Safari, and Opera */
        ::-webkit-scrollbar {
            display: none;
        }

        .table-row {
            background: $white;
            cursor: pointer;
        }

        .table-row:hover {
            background: #e8f0f7;
        }

        .table-cell {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        // little more space for destination
        .destination-cell {
            max-width: 200px;
        }

        .total-clicks-column {
            width: 64px; // min width
        }

        .table-footer {
            position: sticky;
            bottom: 0;
            background: $white;
            z-index: 1;
        }

        .link-actions-container {
            display: flex;
            gap: 10px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .link-table-component {
        .links-table {

            .created-at-column,
            .destination-column {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .link-table-component {

        .filters-container {
            gap: 20px;

            .btn-label {
                display: none;
            }
    
            .filter-btn {
                min-width: 0px;
                width: 20px;
                height: 30px;
            }

            .filter-btn-container {
                padding-bottom: 6px;
            }
        }

        .links-table {
            .url-column, .actions-column {
                display: none;
            }
        }
    }
}