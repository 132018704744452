body {
    font-family: "Roboto", sans-serif;
}

.app {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
}

.main-content {
    flex: 1;
}