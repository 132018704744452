@import '../../../colors.scss';

.link-action-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;

    .modal-content {
        max-width: 450px;
        width: 100%;
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .modal-header-container {
        display: flex;
        font-size: 26px;
        margin-bottom: 20px;
        gap: 20px;

        .modal-header {
            flex-grow: 1;
        }

        .modal-close-btn {
            color: grey;
            cursor: pointer;
            font-size: 20px;
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .url-container {
        border: 1px solid $black;
        border-radius: 8px;
        padding: 10px 20px;
        display: flex;
        margin-top: 10px;
    }

    .url {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .social-container {
        border: 1px solid $grey2;
        border-radius: 8px;
        padding: 14px 20px;
        display: flex;
        gap: 20px;

        .social-header-container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .socal-header {
                font-weight: 500;
            }

            .social-description {
                font-size: 14px;
                margin-top: 6px;
            }
        }

        .main-social-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .icon {
                color: $white;
                background: $primary;
                padding: 10px;
                border-radius: 6px;
                font-size: 18px;
                max-width: 20px;
                max-height: 20px;
            }

            .arrow {
                background: inherit;
                color: $black;
            }
        }
    }

    .social-container:hover {
        background: $grey;
        cursor: pointer;
    }

    .profile-body {
        .social-container {
            padding: 14px 10px;

            .social-icon {
                // font-size: 24px;
                width: 22px;
                height: 22px;
            }
        }
    }

    .social-steps {
        margin-left: 0;
        padding-left: 1.5em;

        li {
            margin-bottom: 14px;
            padding: 0;
        }

        li:last-child {
            margin-bottom: 0;
        }

        .step-header {
            font-weight: 500;
        }
    }

    .btn-container {
        margin-top: 20px;
        width: 100%;

        .social-btn {
            width: 100%;
            padding: 6px;
            font-size: 16px;
        }

        .social-link-out {
            margin-left: 10px;
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .link-action-modal {
        .modal-content {
            margin: 10px;
            padding: 30px 20px;
        }
    }
}