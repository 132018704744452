@import '../../colors.scss';

.mobile-navigation {
    width: 60px;
    // margin: 20px 0 0 20px;
    color: $black;
    position: absolute;
    z-index: 999;
    display: none;

    .toggle-button {
        font-size: 24px;
        margin: 20px 0 0 20px;
    }

    .sidebar-links-container {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        // background: #002948;
        // color: $white;
        background: $white
    }

    .close-icon {
        font-size: 28px;
        margin-left: 20px;
        margin-top: 20px;
        // margin-top: -21px;
    }

    a {
        text-decoration: none;
        // color: $white;
        color: $black;
        width: 90%;
        padding-left: 10px;
        padding: 10px;
        // border-radius: 4px;
    }

    .menu-items {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            align-items: center;
            margin-top: 20px;
            font-size: 20px;
            // margin-left: 2px;
            // border: 1px solid red;

            svg {
                font-size: 20px;
            }

            .item-text {
                display: inline;
                margin-left: 10px;
            }
        }
    }

    .divider-item {
        border-top: 1px solid $grey2;
    }

    .btn-container {
        margin: 40px auto 20px auto;
        width: 80%;
    }

    .btn {
        background: $secondary;
        width: 100%;
        margin: auto;
        font-size: 17px;
        &:hover {
            background: $orange2;
        }
    }

    .active {
        // background-color: #b0c4de;
        border-left: 6px solid $blue2;
        margin-left: 8px;
    }
}

.mobile-navigation.expanded .sidebar-links-container {
    display: block;
}

@media only screen and (max-width: 600px) {
    .mobile-navigation {
        display: block;
    }
}