@import '../../colors.scss';

.aggregate-data-container {
    margin-bottom: 10px;

    .data-container {
        display: flex;
        gap: 20px;
    }

    .data {
        width: 25%;
        text-align: center;
        font-size: 21px;
        font-weight: 500;
        border-right: 1px solid $grey2;
    }

    .data:last-child {
        border-right: none;
    }

    .data-icon {
        font-size: 18px;
        margin-right: 4px;
        color: $blue3;
        width: 20px;
    }

    .date-icon {
        font-size: 18px;
        margin-right: 4px;
        width: 20px;
        padding-top: 2px;
    }

    .data-value {
        font-weight: 600;
        font-size: 26px;
    }

    .loader {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $secondary;
    }

    .icon {
        font-size: 42px;
    }

    .header-date {
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 900px) {
    .aggregate-data-container {
        // margin-top: 10px;

        .header-date {
            // margin-bottom: 0px;
            font-size: 18px;
            padding-left: 10px;
        }

        .data-container {
            flex-direction: column;
            .data {
                width: 100%;
                text-align: left;
                border-right: none;
                padding-left: 10px;
            }
        }
    }
}