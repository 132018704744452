@import '../../colors.scss';

.home-container {
    height: 100%;
    padding-top: 40px;
    color: $black;
    background: $grey;
    overflow-y: auto;

    .main-content {
        text-align: center;
        max-width: 1200px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        margin-bottom: 150px;
    }

    .card {
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        gap: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .img-container {
        width: 200px;
        margin: auto;
    }

    .lemur-img {
        width: 100%;
    }

    .header {
        font-size: 32px;
        text-align: center;
        // margin-top: 10px;
        font-weight: 400;
    }

    .aggregate-header {
        margin-top: 40px;
        font-size: 18px;
    }

    .aggregate-container {
        border: 1px solid $grey2;
        padding: 30px;
        border-radius: 8px;
    }

    .btn-container {
        text-align: center;
        margin: 40px 0 40px 0;

        .btn-header {
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: 500;
        }
    }
}

@media only screen and (max-width: 600px) {
    .home-container {
        .card {
            padding: 30px 10px;
        }
        .aggregate-container {
            // padding-bottom: 20px;
            padding: 20px 10px;
        }
    }
}