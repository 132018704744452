@import '../../colors.scss';

.analytics-container {
    height: 100%;
    padding-top: 40px;
    color: $black;
    background: $grey;
    overflow-y: auto;

    .main-content {
        text-align: center;
        max-width: 1200px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 150px;
    }

    .card {
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        padding: 30px 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .charts-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 column layout */
        gap: 20px;
    }

    .analytics-header {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 10px;

        .link-title {
            font-size: 28px;
            font-weight: 500;
            border-bottom: 1px solid $grey2;
            padding: 0 10px;
        }
    }

    .warning {
        background: $yellow;
        border: 1px solid $yellow;
        color: #111;
        text-align: center;
        font-size: 20px;
    }

    .header-container {
        display: flex;
    }

    .header {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
        display: flex;
        display: inline-block;
        border-bottom: 1px solid $grey2;
        padding: 0 2px;
    }

    .data-icon {
        font-size: 18px;
        margin-right: 4px;
        width: 20px;
        padding-top: 2px;
    }

    .header-half {
        width: 50%;
    }

    .header-date {
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    .location-control-container {
        text-align: right;
        flex-grow: 1;
    }

    .form-control {
        width: 160px
    }

    .date-range-component {
        width: 100%;
    }

    .date-card {
        max-width: 350px;
        background: $grey;
        border: none;
        box-shadow: none;
        padding: 0 !important;
    }
}

@media only screen and (max-width: 900px) {
    .analytics-container {
        padding-top: 20px;

        .card {
            padding: 30px 10px;
        }

        .charts-container {
            grid-template-columns: 1fr; /* Single column layout */
        }

        .header-container {
            flex-direction: column;
        }

        .form-control {
            margin: 20px 0 30px 4px;
        }

        .header-date {
            margin-bottom: 0px;
            font-size: 18px;
            padding-left: 10px;
        }

        .location-header {
            margin-bottom: 0;
            width: fit-content;
        }

        .location-control-container {
            text-align: left;
        }

        .date-card {
            max-width: 100%;
        }
    }
}