@import '../../colors.scss';

.settings-container {
    height: 100%;
    padding-top: 40px;
    color: $black;
    background: $grey;
    overflow-y: auto;

    .main-content {
        text-align: center;
        max-width: 1200px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 150px;
    }

    .settings-header {
        font-size: 32px;
        font-weight: 700;
    }

    .card {
        border-radius: 8px;
        background: $white;
        border: 1px solid $white;
        padding: 30px 60px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .form-container {
        max-width: 600px;
    }

    .form-title {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .member {
        background: $blue4;
        padding: 6px 10px;
        display: inline-block;
        border-radius: 6px;
        font-size: 16px;
    }

    .settings-form {
        display: flex;
        flex-direction: column;

        .settings-section-header {
            font-size: 20px;
            font-weight: 600;
            margin: 40px 0 10px 0;

            .changing-message {
                font-size: 14px;
                font-weight: 400;
                margin-top: 4px;
            }
        }
    }

    .form-item {
        margin-bottom: 30px;
        width: 100%;
    }

    .text-field {
        width: 100%;
    }

    .btn-container {
        margin: 40px 0 20px 0;
        display: flex;
        justify-content: flex-end;
        gap: 14px;
    }

    .link {
        text-decoration: none;
        color: $black;
        font-weight: 500;
    }

    .link:hover {
        text-decoration: underline;
    }
}

@media only screen and (max-width: 600px) {
    .settings-container {
        .card {
            padding: 30px 10px;
        }

        .form-container {
            width: 100%;
        }

        .flex {
            flex-direction: column;
        }

        .form-item {
            width: 100%;
        }
    }
}