@import '../../colors.scss';

.links-component {
    height: 100%;
    padding-top: 40px;
    color: $black;
    background: $grey;
    overflow-y: auto;

    .main-content {
        text-align: center;
        max-width: 1200px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 85%;
        margin-bottom: 100px;
    }

    .links-header {
        font-size: 32px;
        font-weight: 700;
    }

    // not needed on dekstop
    // easy way to offset from bottom on mobile below table
    .table-spacer {
        height: 10px;
    }

    .parent-table-container {
        flex-grow: 1;
        height: 100%;
        overflow: auto;
        padding-bottom: 50px; // ensure footer doesnt get lost
    }

    .link-cards {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .link-end-container {
        width: 350px;
        margin: auto;
        font-size: 16px;

        .link-end {
            margin-top: 30px;
            display: flex;
            align-items: center;
            width: 100%;
            gap: 2px;

            .slash {
                flex: 1;
                height: 1px;
                background-color: $black;
                margin: 0 10px;
            }
        }
    }

    .no-links-container {
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        background: $white;
        border: 1px solid $white;
        padding: 60px 40px;
        gap: 10px;
        align-items: center;

        .no-link-text {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .btn {
            background: $blue2;
        }
    }

    .loader {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $secondary;
        margin-top: -300px;
    }

    .icon {
        font-size: 42px;
    }
}

@media only screen and (max-width: 900px) {
    .links-component {
        padding-top: 20px;
    }
}