@import '../../colors.scss';

.users-component {
    height: 100%;
    padding-top: 40px;
    color: $black;
    background: $grey;
    overflow-y: auto;

    .main-content {
        text-align: center;
        max-width: 1200px;
        margin: auto;
        text-align: left;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        // gap: 20px;
        height: 85%;
        margin-bottom: 100px;
    }

    .users-header {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    // not needed on dekstop
    // easy way to offset from bottom on mobile below table
    .table-spacer {
        height: 0;
    }

    .parent-table-container {
        flex-grow: 1;
        height: 100%;
        overflow: auto;
        padding-bottom: 30px; // ensure footer doesnt get lost
    }

    .no-users-container {
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        background: $white;
        border: 1px solid $white;
        padding: 60px 40px;
        gap: 10px;
        align-items: center;

        .no-users-text {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    .loader {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $secondary;
        margin-top: -200px;
    }

    .icon {
        font-size: 42px;
    }

    .filters-container {
        padding: 20px;
        background: $white;
        border-radius: 6px 6px 0 0;

        .filter-item {
            max-width: 350px;
        }
    }

    .parent-table-container {
        flex-grow: 1;
        height: 100%;
        overflow: auto;
        padding-bottom: 30px; // ensure footer doesnt get lost
        background: $white;
    }

    .users-table {
        height: 89%;
        border-top: 1px solid $grey2; // material's color
        overflow: auto;
        position: relative;
        scrollbar-width: none; // Hide scrollbar for Firefox
        -ms-overflow-style: none; // Hide scrollbar for IE and Edge
        /* Hide scrollbar for Chrome, Safari, and Opera */
        ::-webkit-scrollbar {
            display: none;
        }

        .table-row {
            background: $white;
            cursor: pointer;
        }

        .table-row:hover {
            background: #e8f0f7;
        }
    }
}

@media only screen and (max-width: 900px) {
    .users-component {
        padding-top: 20px;

        .filters-container {
            .filter-item {
                max-width: 100%;
            }
        }

        .id-column, .email-column, .permission-column, .plan-column, .verified-column {
            display: none;
        }
    }
}