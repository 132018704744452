@import '../../colors.scss';

.link-action-btn-container {
    .link-action-btn {
        min-width: 0px;
        width: 20px;
        height: 30px;
        border: 1px solid $grey2;
    }

    .btn-icon {
        font-size: 14px;
    }
}