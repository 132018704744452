@import '../../colors.scss';

.sidebar {
    width: 65px;
    height: 100%;
    color: $white;
    transition: width 0.3s ease-in-out;
    overflow-x: hidden;
    border-right: 1px solid $grey2;
    background: $primary;
    display: block;

    &.expanded {
        width: 240px;

        .btn-container {
            width: 210px;
            margin: auto;

            a {
                padding: 0;
            }

            .btn {
                width: 100%;
            }
        }
        .toggle-button-container {
            text-align: right;
        }
    }

    .toggle-button-container {
        text-align: center;
        margin: 10px 0;
    }

    .toggle-button {
        cursor: pointer;
        padding: 4px 10px;
        text-align: center;
        font-size: 22px;
        text-align: right;
        opacity: .8;

        &:hover {
            background-color: $blue2;
        }
    }

    .menu-items {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;

            &:hover {
                background-color: $blue2;
            }

            svg {
                font-size: 20px;
                // padding-left: 11px;
            }

            .item-text {
                display: inline;
                margin-left: 10px;
            }
        }
    }

    .img-container {
        width: 40px;
        margin: 10px 0 0 4px;
        cursor: pointer;
    }

    .lemur-img {
        width: 100%;
    }

    a {
        color: $white;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        cursor: pointer;
    }

    .btn-container {
        padding-bottom: 10px;
    }

    .btn {
        min-width: 0px;
        background: $secondary;
        &:hover {
            background: $orange2;
        }
    }

    .divider-item {
        // margin-top: 20px;
        border-top: 1px solid $grey2;
        margin-top: 20px;
    }

    .active {
        background-color: $blue2;
    }
}

@media only screen and (max-width: 600px) {
    .sidebar {
        display: none;
    }
}