@import '../../colors.scss';

.terms-of-service {
    height: 100%;
    // background: $grey;
    overflow-y: auto;
    padding-top: 10px;

    .main-content {
        max-width: 800px;
        margin: auto;
        padding: 20px;
        margin-bottom: 150px;
        overflow-y: auto;

        h1 {
            margin-top: 0;
        }
    
        li {
            padding: 10px;
        }

        .list-head {
            font-weight: 600;
        }

        .thank-you {
            text-align: center;
            color: $blue2;
            font-size: 20px;
            margin-top: 30px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .terms-of-service {
        .main-content {
            h1 {
                font-size: 28px;
            }

            ol {
                padding-left: 20px;
            }
        }
    }
}